import React from 'react';
import PageLayout from '../layout';
import { CTAColumn } from 'nbt-uikit';

class NotFoundPage extends React.Component {
  render() {
    return (
      <PageLayout>
        <CTAColumn type={'light'}
                   title={'Page Not Found'}
                   description={'Sorry, there is nothing at this location.'}
                   button={
                     {
                       text: 'Go to the home page',
                       link: '/'
                     }
                   }
        />
      </PageLayout>
    );
  }
}

export default NotFoundPage;
